<template>
  <div class="w-100 white" id="enable-multi-factor-form" :class="wrapperClass">
    <v-form
      ref="enrollMfaForm"
      v-model="isFormValid"
      @submit.prevent="submitHandler"
    >
      <div v-if="!isOtpSent">
        <slot name="header" />
        <div id="multi-factor__headings" v-if="showHeader">
          <v-row class="mb-1">
            <v-col cols="12" lg="12">
              <h3 class="secondary--font text--primary font-weight-large pb-4">
                Multi-Factor Authentication
                {{ isMfaRequired ? " Required" : "" }}
              </h3>
              <div
                :class="contentClass"
                class="text--secondary body-2 mb-3 enroll-mfa__description"
              >
                <h5 class="text--secondary body-2 mb-4">
                  {{ heading }}
                </h5>
                <p class="mb-2">
                  You will be required to provide two forms of identification
                  when logging in:
                </p>
                <ol type="1">
                  <li>Your password</li>
                  <li>
                    An authentication code - sent to your phone number via text
                    message
                  </li>
                </ol>
              </div>
            </v-col>
          </v-row>
        </div>

        <slot name="successMessage" />
        <div v-if="errorMessage">
          <alert-message :message="errorMessage" />
        </div>
      </div>

      <div class="form-group">
        <div id="mfa-input-fields" class="mb-3" v-if="!isOtpSent">
          <div class="d-flex align-center pb-2">
            <label
              for="phone"
              class="subtitle-2 text--secondary font-weight-medium"
            >
              Enter Your Phone Number
            </label>
            <tooltip
              v-if="showPhoneInfo"
              width="300px"
              :type="$appConfig.tooltip.type.dark"
              name="Select your country from the drop-down and enter your phone number in the format (123) 456-7890."
            >
              <template #default="{ on, attrs }">
                <v-icon class="pl-1 cursor-pointer" v-on="on" v-bind="attrs">
                  mdi-information-outline
                </v-icon>
              </template>
            </tooltip>
          </div>

          <v-text-field
            id="phone"
            v-model="credentials.phoneNumber"
            placeholder="Phone Number *"
            class="required"
            hide-details="auto"
            maxlength="15"
            outlined
            type="tel"
            @input="inputCompleteNumber"
            :rules="phoneFieldRules"
          />
        </div>

        <div class="d-flex" :class="captchaWrapperClass">
          <div id="recaptcha-container" />
        </div>
        <!-- RENDERS OTP FORM AFTER OTP IS SENT STARTS HERE -->
        <template v-if="isOtpSent">
          <v-card :class="isMfaRequired ? 'pa-0 elevation-0' : 'pa-8'">
            <enter-otp-form
              @submit="verifyOtp"
              wrapper-width="500px"
              :is-loading="isLoading"
              :phone-number="maskedPhoneNumber"
              @reauthenticate="reauthenticateHandler"
              :text-field-class="isMfaRequired ? 'pr-1' : 'pr-2 pr-sm-4'"
              track-id="enroll-mfa-login-button"
              :prepend-text="prependText"
              @form-status="isOtpEntered = $event"
              :is-code-sending="isCodeSending"
              :show-resend-code-option="showResendCodeOption"
              :is-resend-code-allowed="isResendCodeAllowed"
              :resend-verification-code="resendVerificationCode"
            >
              <template #heading>
                <h2
                  class="
                    text-h6 text--primary
                    font-weight-large
                    secondary--font
                  "
                >
                  {{ otpHeaderTitle }}
                </h2>
              </template>
              <template #alertMessage>
                <div v-if="errorMessage">
                  <alert-message :message="errorMessage" />
                </div>
              </template>
              <template #buttons v-if="!isMfaRequired">
                <v-row class="mt-6">
                  <v-col align="end">
                    <v-btn
                      @click="reauthenticateHandler"
                      text
                      class="font-weight-bold mr-2"
                      v-track="'enable-mfa-verfiy-otp-cancel-btn'"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      type="submit"
                      color="dark-black"
                      :disabled="!isOtpEntered"
                      :loading="isLoading || isProcessing"
                      class="font-weight-bold white--text"
                      v-track="'enable-mfa-verfiy-otp-submit-btn'"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </enter-otp-form>
          </v-card>
        </template>
        <!-- RENDERS OTP FORM ENDS HERE -->

        <!-- BUTTONS SLOT STARTS -->
        <slot name="buttons" />
        <!-- BUTTONS SLOT ENDS -->

        <v-row class="mt-6" v-if="!isOtpSent && !hasButtonsSlot">
          <v-col align="end">
            <v-btn
              @click="closeDialog"
              text
              class="font-weight-bold mr-2"
              v-track="'enable-mfa-cancel-btn'"
            >
              Cancel</v-btn
            >
            <v-btn
              type="submit"
              color="dark-black"
              :disabled="isButtonDisabled"
              v-track="'enable-mfa-next-btn'"
              class="font-weight-bold white--text"
            >
              Next</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils";
import {
  auth
} from "@/services/auth";
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor
  } from 'firebase/auth'

import "intl-tel-input/build/css/intlTelInput.css";
import { required, onlyNumberKey } from "@/validators/form-validators";

import Tooltip from "@/components/shared/Tooltip.vue";
import EnterOTPForm from "@/components/forms/EnterOTPForm.vue";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import { hasProp, maskPhoneNumber, defer } from "@/utils";
import {
  AUTH_ERROR_CASE_MESSAGES,
  RECAPTCHA_TYPES,
  RESEND_VERIFICATION_CODE_MILLIS,
  DEFAULT_PHONE_COUNTRY_CODE,
} from "@/constants/app";
import { getClientCountry } from "@/services/public";

/**
 * Enrolls an existing user with Multi factor authentication
 */
export default {
  name: "EnrollMultiFactorForm",
  /**
  |--------------------------------------------------
  | Custom events
  |--------------------------------------------------
  */
  emits: ["close-dialog", "otp-sent", "disable-submit-btn", "success"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    resetForm: { type: Boolean, default: false },
    wrapperClass: { type: String, default: "mt-4" },
    contentClass: { type: String, default: "" },
    isMfaRequired: { type: Boolean, default: false },
    isHeadingPrimary: { type: Boolean, default: false },
    showPhoneInfo: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    phoneNumberInputClass: { type: String, default: "phone-number__wrapper" },
    captchaWrapperClass: { type: String, default: "justify-start" },
    isProcessing: { type: Boolean, default: false },
    updateSession: { type: Boolean, default: true },
    onSuccess: { type: Function, default: () => {} },
    otpHeaderTitle: { type: String, default: "Text Message Authentication" },
    prependText: {
      type: String,
      default: "turn on multi-factor authentication.",
    },
    heading: {
      type: String,
      default: `Multi-Factor Authentication improves security for your
                  account.`,
    },
    showResendCodeOption: { type: Boolean, default: false },
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Tooltip,
    AlertMessage,
    "enter-otp-form": EnterOTPForm,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      verificationId: null,
      credentials: { phoneNumber: "", verificationCode: "" },
      isLoading: false,
      isButtonDisabled: true,
      recaptchaVerified: false,
      isFormValid: false,
      errorMessage: "",
      recaptchaVerifier: null,
      telInputField: null,
      completePhoneNumber: "",
      isOtpEntered: false,
      isCodeSending: false,
      isResendCodeAllowed: false,
      recaptchaType: RECAPTCHA_TYPES.normal,
      recaptchaId: null,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Is buttons slot user by parent component
     @type {Boolean}
     */
    hasButtonsSlot() {
      return hasProp(this.$slots, "buttons");
    },
    /**
     * Masked mobile no of the user
     */
    maskedPhoneNumber() {
      return maskPhoneNumber(this.completePhoneNumber);
    },
    /**
     * Phone number input field
     * @type {Array}
     */
    phoneFieldRules() {
      return [
        (v) => !!v || "Phone Number is required",
        (v) => !v || !!v.trim() || "Phone Number cannot be blank",
        (v) => (v && v.length >= 5) || "Minimum number of digits allowed is 5",
        (v) =>
          (v && v.length <= 15) || "Maximum number of digits allowed is 15",
        (v) =>
          (v && /^[+]?[0-9]+$/.test(v)) ||
          `Phone Number should only contain numeric values`,
      ];
    },
    /**
     * Is otp sent to the user or not
     * @type {Boolean}
     */
    isOtpSent() {
      return this.verificationId;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Computes and validates complete phone number entered by the user
     * @listens input Phone number input
     */
    inputCompleteNumber() {
      this.completePhoneNumber = this.telInputField.getNumber();
      this.isSubmitBtnDisabled();
    },
    /**
     * @emits close-dialog event in parent component to close the dialog
     */
    closeDialog() {
      this.$emit("close-dialog", false);
    },
    /**
     * Verify the otp eneterd by the user
     * @param {String} otp Otp eneterd by the user
     */
    verifyOtp(otp) {
      this.credentials.verificationCode = otp;
      this.enrollMultiFactor();
    },
    /**
     * Reauthenticate handler
     */
    reauthenticateHandler() {
      this.closeDialog();
      this.errorMessage = "";
      this.isFormValid = false;
      this.verificationId = null;
      this.credentials.verificationCode = "";
    },
    /**
     * Show loader to the user
     */
    showLoader(val) {
      this.isLoading = val;
    },
    /**
     * Enroll mfa for a user form submit handler
     * @listens submit
     */
    submitHandler() {
      if (!this.isFormValid) return this.$refs.enrollMfaForm.validate();
      this.completePhoneNumber = this.telInputField.getNumber();

      this.enrollMultiFactor();
    },
    required,
    onlyNumberKey,
    /**
     * resetResendCodeVerificationTimer
     * @description Resets isResendCodeAllowed true after 15000 milli seconds
     */
    resetResendCodeVerificationTimer() {
      this.isResendCodeAllowed = false;
      defer(() => {
        this.isResendCodeAllowed = true;
      }, RESEND_VERIFICATION_CODE_MILLIS);
    },
    /**
     * resendVerificationCode
     * @description Resend code (OTP) to user MFA phone number
     */
    async resendVerificationCode() {
      try {
        let widgetId;
        this.isCodeSending = true;
        if (
          this.recaptchaId &&
          this.recaptchaType === RECAPTCHA_TYPES.invisible
        ) {
          widgetId = this.recaptchaId;
        } else {
          this.recaptchaType = RECAPTCHA_TYPES.invisible;
          await this.recaptchaVerifier.clear();
          widgetId = await this.verifyRecaptchaVerifier();
        }

        await window.grecaptcha.reset(widgetId);
        await this.sendVerificationCode();
        this.resetResendCodeVerificationTimer();
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.isCodeSending = false;
      }
    },
    /**
     * Maps vuex store actions in the component
     */
    ...mapActions({
      updateUserSession: "auth/updateUserSession",
      setCurrentUserDetails: "auth/setCurrentUserDetails",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
    }),
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Handles error message and shows human readable message to the user
     */
    showErrorMessage(error) {
      const { commonErrorMessage } = this.$appConfig.commonErrorMessage;

      let message = AUTH_ERROR_CASE_MESSAGES[error?.code] ?? error.message;
      this.errorMessage = message ?? commonErrorMessage;
    },
    async sendVerificationCode() {
      const multiFactorSession = await multiFactor(auth?.currentUser).getSession();

      // Specify the phone number and pass the MFA session.
      var phoneInfoOptions = {
        phoneNumber: this.completePhoneNumber,
        session: multiFactorSession,
      };
      var phoneAuthProvider = new PhoneAuthProvider(auth);

      // Send SMS verification code.
      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      );
      this.resetResendCodeVerificationTimer();
      this.$emit("otp-sent", true);
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        this.setIsLoading(true);
        this.errorMessage = "";

        // If verification id is present stop functions exceution here and verify sms code
        if (this.verificationId) {
          await this.verifySmsCode();
          return;
        }
        await this.sendVerificationCode();
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verofy sms code of the user
     */
    async verifySmsCode() {
      try {
        this.setIsLoading(true);
        var cred = PhoneAuthProvider.credential(
          this.verificationId,
          this.credentials.verificationCode
        );
        var multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(cred);

        await multiFactor(auth?.currentUser).enroll(multiFactorAssertion, "");

        await this.setCurentUserMfaDetails();
        // Enforcing mfa for a user whose mfa is not enabled or setup
        if (this.isMfaRequired) {
          await this.setCurrentUserDetails(auth?.currentUser);
        } else if (this.updateSession) {
          await this.updateUserSession({ removeCookieFirst: true });
        } else {
          await this.onSuccess();
        }

        this.$emit("success", this.completePhoneNumber);
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verifies and displayes recaptcha
     */
    async verifyRecaptchaVerifier() {
      try {
        this.setIsLoading(true);
        const CAPTACHA_CONTAINER_ID = "recaptcha-container";
        const REF = this;
        this.recaptchaVerifier = new RecaptchaVerifier(auth,
          CAPTACHA_CONTAINER_ID,
          {
            size: this.recaptchaType,
            callback: () => {
              // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
              REF.recaptchaVerified = true;
              REF.isSubmitBtnDisabled();
            },
          }
        );

        this.recaptchaId = await this.recaptchaVerifier.render();
        return this.recaptchaId;
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Disables mfa form button of required fields are not present
     * @emits disable-submit-btn
     */
    isSubmitBtnDisabled() {
      this.isButtonDisabled = !this.isFormValid || !this.recaptchaVerified;
      this.$emit("disable-submit-btn", this.isButtonDisabled);
    },
    /**
     * Verfies user country code authenticity
     * @returns {Boolean}
     */
    isUserCountryValid(countryCode) {
      const countriesList = window?.intlTelInputGlobals?.getCountryData();

      return (
        countriesList.findIndex(
          ({ iso2 }) => iso2 && countryCode?.toLowerCase() === iso2
        ) > -1
      );
    },
    /**
     * Fetches logged in user country code
     */
    async fetchUserCountry(callback) {
      let countryCode = DEFAULT_PHONE_COUNTRY_CODE;
      try {
        this.showLoader(true);
        const { data } = await getClientCountry();
        const userCountryCode =
          data && data.length > 0 ? data?.split(";")?.[1] : countryCode;

        const isCountryValid = this.isUserCountryValid(userCountryCode);
        if (isCountryValid) countryCode = userCountryCode;

        callback(countryCode);
      } catch {
        callback(countryCode);
      } finally {
        this.showLoader(false);
      }
    },
    /**
     * Initialises phone number input field with different countries support
     */
    async initialiseTelephoneInput() {
      const input = document.querySelector("#phone");
      this.telInputField = intlTelInput(input, {
        utilsScript: utils,
        nationalMode: true,
        initialCountry: "auto",
        separateDialCode: true,
        customContainer: this.phoneNumberInputClass,
        geoIpLookup: this.fetchUserCountry,
      });
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.$nextTick(() => {
      this.verifyRecaptchaVerifier();
      this.initialiseTelephoneInput();
    });
  },
};
</script>

<style lang="scss">
.phone-number__wrapper {
  .iti__flag-container {
    .iti__country-list {
      width: 424px !important;
      padding-left: 0px !important ;
    }
  }
}
.login-phone-number__wrapper {
  .iti__flag-container {
    .iti__country-list {
      width: 332px !important;
      padding-left: 0px !important;
    }
  }
}
</style>
